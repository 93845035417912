export const US_STATE_LIST = [
  {
    value: "Alaska",
    label: "Alaska",
  },
  {
    value: "Alabama",
    label: "Alabama",
  },
  {
    value: "Arkansas",
    label: "Arkansas",
  },
  {
    value: "American Samoa",
    label: "American Samoa",
  },
  {
    value: "Arizona",
    label: "Arizona",
  },
  {
    value: "California",
    label: "California",
  },
  {
    value: "Colorado",
    label: "Colorado",
  },
  {
    value: "Connecticut",
    label: "Connecticut",
  },
  {
    value: "District of Columbia",
    label: "District of Columbia",
  },
  {
    value: "Delaware",
    label: "Delaware",
  },
  {
    value: "Florida",
    label: "Florida",
  },
  {
    value: "Georgia",
    label: "Georgia",
  },
  {
    value: "Guam",
    label: "Guam",
  },
  {
    value: "Hawaii",
    label: "Hawaii",
  },
  {
    value: "Iowa",
    label: "Iowa",
  },
  {
    value: "Idaho",
    label: "Idaho",
  },
  {
    value: "Illinois",
    label: "Illinois",
  },
  {
    value: "Indiana",
    label: "Indiana",
  },
  {
    value: "Kansas",
    label: "Kansas",
  },
  {
    value: "Kentucky",
    label: "Kentucky",
  },
  {
    value: "Louisiana",
    label: "Louisiana",
  },
  {
    value: "Massachusetts",
    label: "Massachusetts",
  },
  {
    value: "Maryland",
    label: "Maryland",
  },
  {
    value: "Maine",
    label: "Maine",
  },
  {
    value: "Michigan",
    label: "Michigan",
  },
  {
    value: "Minnesota",
    label: "Minnesota",
  },
  {
    value: "Missouri",
    label: "Missouri",
  },
  {
    value: "Mississippi",
    label: "Mississippi",
  },
  {
    value: "Montana",
    label: "Montana",
  },
  {
    value: "North Carolina",
    label: "North Carolina",
  },
  {
    value: "North Dakota",
    label: "North Dakota",
  },
  {
    value: "Nebraska",
    label: "Nebraska",
  },
  {
    value: "New Hampshire",
    label: "New Hampshire",
  },
  {
    value: "New Jersey",
    label: "New Jersey",
  },
  {
    value: "New Mexico",
    label: "New Mexico",
  },
  {
    value: "Nevada",
    label: "Nevada",
  },
  {
    value: "New York",
    label: "New York",
  },
  {
    value: "Ohio",
    label: "Ohio",
  },
  {
    value: "Oklahoma",
    label: "Oklahoma",
  },
  {
    value: "Oregon",
    label: "Oregon",
  },
  {
    value: "Pennsylvania",
    label: "Pennsylvania",
  },
  {
    value: "Puerto Rico",
    label: "Puerto Rico",
  },
  {
    value: "Rhode Island",
    label: "Rhode Island",
  },
  {
    value: "South Carolina",
    label: "South Carolina",
  },
  {
    value: "South Dakota",
    label: "South Dakota",
  },
  {
    value: "Tennessee",
    label: "Tennessee",
  },
  {
    value: "Texas",
    label: "Texas",
  },
  {
    value: "Utah",
    label: "Utah",
  },
  {
    value: "Virginia",
    label: "Virginia",
  },
  {
    value: "Virgin Islands",
    label: "Virgin Islands",
  },
  {
    value: "Vermont",
    label: "Vermont",
  },
  {
    value: "Washington",
    label: "Washington",
  },
  {
    value: "Wisconsin",
    label: "Wisconsin",
  },
  {
    value: "West Virginia",
    label: "West Virginia",
  },
  {
    value: "Wyoming",
    label: "Wyoming",
  },
];

export const CITIZENSHIP_STATUS_OPTIONS = [
  {
    value: "CITIZEN",
    label: "U.S Citizen",
  },
  {
    value: "RESIDENT",
    label: "U.S Resident",
  },
  {
    value: "NON_RESIDENT",
    label: "non-Resident",
  },
];

// Appliction Status
export const APPLICATION_STATUS_APPROVED = "APPROVED";
export const APPLICATION_STATUS_PENDING = "PENDING";
export const APPLICATION_STATUS_REJECTED = "REJECTED";
export const APPLICATION_STATUS_REQUIRES_UPDATE = "REQUIRES_UPDATE";

// Douments Types
export const DOCUMENT_TYPE_DRIVERS_LICENSE = "DRIVERS_LICENSE";
export const DOCUMENT_TYPE_PASSPORT = "PASSPORT";
export const DOCUMENT_TYPE_STATE_ID = "STATE_ID";

// Document Orientation
export const DOCUMENT_ORIENTATION_FRONT = "FRONT";
export const DOCUMENT_ORIENTATION_BACK = "BACK";

// Onboarding Status
export const ONBOARDING_STATUS_NOT_ONBOARDED = "NOT_ONBOARDED";
export const ONBOARDING_STATUS_ONBOARDED_NO_VERIFICATION =
  "ONBOARDED_NO_VERIFICATION";
export const ONBOARDING_STATUS_VERIFICATION_FAILED = "VERIFICATION_FAILED";
export const ONBOARDING_STATUS_PENDING = "PENDING";
export const ONBOARDING_STATUS_ONBOARDED_WITH_ERROR = "ONBOARDED_WITH_ERROR";
export const ONBOARDING_STATUS_ONBOARDED_NO_ACCREDITATION =
  "ONBOARDED_NO_ACCREDITATION";

// Account Activity
export const ACTIVITY_LOGIN = "LOGIN";
export const ACTIVITY_PASSWORD_RESET = "PASSWORD_RESET";
export const ACTIVITY_ACCOUNT_CREATION = "ACCOUNT_CREATION";
export const ACTIVITY_OTHER_THINGS_TO_ADD = "OTHER_THINGS_TO_ADD";

// API endpoints

// Applications
export const API_ENDPOINT_APPLICATION_CREATE = "applications/create";
export const API_ENDPOINT_APPLICATION_READ = "applications/read/"; // {param: id}
export const API_ENDPOINT_APPLICATION_UPDATE = "applications/update/"; // {param: id}
export const API_ENDPOINT_APPLICATION_DELETE = "applications/delete/"; // {param: id}
export const API_ENDPOINT_APPLICATION_SEARCH = "applications/search";
export const API_ENDPOINT_APPLICATION_LIST = "applications/list";

// Authentication

export const API_ENDPOINT_AUTHENTICATION_LOGIN = "user/login";
export const API_ENDPOINT_AUTHENTICATION_LOGOUT = "admin/logout";
export const API_ENDPOINT_AUTHENTICATION_REGISTER = "user/register";

// Authentication

export const API_ENDPOINT_USER_INFO = "user/me";
export const API_ENDPOINT_USER_LOGOUT = "user/logout";
export const API_ENDPOINT_USER_UPDATE_PROFILE = "user/update-profile";
export const API_ENDPOINT_USER_UPDATE_PASSWORD = "user/password-update";

// Documents
export const API_ENDPOINT_DOCUMENT_CREATE = "documents/create";
export const API_ENDPOINT_DOCUMENT_LIST = "documents/list";

// Documents
export const API_ENDPOINT_COUNTRIES_LIST = "countries/list";

// Accreditation status
export const ACCREDITED_BY_INCOME = "ACCREDITED_BY_INCOME";
export const ASSOCIATED_WITH_BROKER_DEALER = "ASSOCIATED_WITH_BROKER_DEALER";
export const NOT_ACCREDITED = "NOT_ACCREDITED";

// Admin routes
export const API_ENDPOINT_ADMIN_AUTHENTICATION_LOGIN = "admin/login";
export const API_ENDPOINT_ADMIN_LIST_USERS = "admin/list";

// Docusign
export const API_ENDPOINT_FOCUSED_VIEW = "docusign/focused-view/";

// OTP
export const API_ENDPOINT_OTP_RESEND = "user/resend-otp"; // POST
export const API_ENDPOINT_OTP_VALIDATE = "user/otp"; // POST

// Onboarding steps
export const ONBOARDING_STEP_PHONE_VERIFICATION = 1;
// export const ONBOARDING_STEP_EMAIL_VERIFICATION = 2;
export const ONBOARDING_STEP_PERSONAL_INFORMATION = 2;
export const ONBOARDING_STEP_ADDRESS_INFORMATION = 3;
export const ONBOARDING_STEP_ACCREDITATION_INFORMATION = 4;
export const ONBOARDING_STEP_INCOME_INFORMATION = 5;
export const ONBOARDING_STEP_COUNTRY_OF_CITIZENSHIP = 6;
export const ONBOARDING_STEP_DOCUMENTS_UPLOAD = 7;
export const ONBOARDING_STEP_AWAITING_VERIFICATION = 8;
export const ONBOARDING_STEP_DOCUMENT_SIGNING = 9;
export const ONBOARDING_STEP_VERIFICATION_RESULT = 10;
export const ONBOARDING_STEP_DOCUMENT_SIGNED = 11;

// Onboarding steps text
export const onboardingStepsTexts = [
  {
    title: "Phone Verification",
    description: "Let's make sure you have provided a valid phone number",
    percent: 0,
  },
  /*{
    title: "E-mail Verification",
    description: "Let's make sure you have provided a valid e-mail address",
    percent: 0,
  },*/
  {
    title: "Personal Information",
    description: "We want to know more about you",
    percent: 0,
  },
  {
    title: "Address Information",
    description: "We want to know more about where you live",
    percent: 0,
  },
  {
    title: "Accreditation",
    description: "Let's us know what is your investor profile",
    percent: 0,
  },
  {
    title: "Income",
    description: "We want to know more about your incomes",
    percent: 0,
  },
  {
    title: "Country of Citizenship",
    description: "Provide information about your citizenship",
    percent: 0,
  },
  {
    title: "Doocuments Upload",
    description: "We need some documents to verify your identity",
    percent: 0,
  },
  {
    title: "Awaiting Verification",
    description: "Please wait while we verify your information and documents",
    percent: 0,
  },
  {
    title: "Document Signing",
    description:
      "We need your review and digital signature into legal documents. Click the button below to start the digital signature process.",
    percent: 0,
  },
];
