import { API_BASE_URL, ACCESS_TOKEN_NAME } from "@/config/serverApiConfig";

import axios from "axios";
import errorHandler from "@/request/errorHandler";
import successHandler from "@/request/successHandler";
import storePersist from "@/redux/storePersist";
import { getCookie, setCookie, deleteCookie } from "./cookie";
import {
  API_ENDPOINT_ADMIN_AUTHENTICATION_LOGIN,
  API_ENDPOINT_AUTHENTICATION_LOGIN,
  API_ENDPOINT_AUTHENTICATION_LOGOUT,
  API_ENDPOINT_AUTHENTICATION_REGISTER,
  API_ENDPOINT_USER_LOGOUT,
} from "@/shared/constants";

export const login = async (loginData) => {
  try {
    const response = await axios.post(
      API_BASE_URL + API_ENDPOINT_AUTHENTICATION_LOGIN,
      loginData
    );
    token.set(response.data.result.token);
    return successHandler(response);
  } catch (error) {
    return errorHandler(error);
  }
};

export const register = async (registerData) => {
  try {
    const response = await axios.post(
      API_BASE_URL + API_ENDPOINT_AUTHENTICATION_REGISTER,
      registerData
    );
    token.set(response.data.result.token);

    return successHandler(response);
  } catch (error) {
    console.log("ERROR HERE: ", error);
    return errorHandler(error);
  }
};

export const logout = async () => {
  const response = await axios.post(API_BASE_URL + API_ENDPOINT_USER_LOGOUT);
  if (response) {
    token.remove();
    storePersist.clear();
  }
};

export const token = {
  get: () => {
    return getCookie(ACCESS_TOKEN_NAME);
  },
  set: (token) => {
    return setCookie(ACCESS_TOKEN_NAME, token);
  },
  remove: () => {
    return deleteCookie(ACCESS_TOKEN_NAME);
  },
};
