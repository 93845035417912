import React, { lazy, Suspense } from "react";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import PrivateRoute from "./PrivateRoute";
import PageLoader from "@/components/PageLoader";
import { useSelector } from "react-redux";
import { selectAuth } from "@/redux/auth/selectors";

const ClientDashboard = lazy(() =>
  import(/*webpackChunkName:'ClientDashboardPage'*/ "@/pages/ClientDashboard")
);

const AccountValidation = lazy(() =>
  import(
    /*webpackChunkName:'AccountValidationPage'*/ "@/pages/AccountValidation"
  )
);

const ForgotPassword = lazy(() =>
  import(/*webpackChunkName:'ForgotPassowrdPage'*/ "@/pages/ForgotPassword")
);

const ChangePassword = lazy(() =>
  import(/*webpackChunkName:'ChangePassowrdPage'*/ "@/pages/ChangePassword")
);

const DocusignView = lazy(() =>
  import(/*webpackChunkName:'DocusignViewPage'*/ "@/pages/DocusignView")
);

const Logout = lazy(() =>
  import(/*webpackChunkName:'LogoutPage'*/ "@/pages/Logout")
);
const NotFound = lazy(() =>
  import(/*webpackChunkName:'NotFoundPage'*/ "@/pages/NotFound")
);

export default function AppRouter() {
  const location = useLocation();
  const authSelector = useSelector(selectAuth);

  return (
    <Suspense fallback={<PageLoader />}>
      <AnimatePresence exitBeforeEnter initial={false}>
        <Switch location={location} key={location.pathname}>
          <PrivateRoute path="/" component={ClientDashboard} exact />
          <PrivateRoute
            component={ForgotPassword}
            path="/forgot-password"
            exact
          />
          <PrivateRoute
            component={ChangePassword}
            path="/change-password"
            exact
          />
          <PrivateRoute
            component={AccountValidation}
            path="/account-validation"
            exact
          />          
          <PrivateRoute
            component={ClientDashboard}
            path="/success"
            exact
          />
          <PrivateRoute component={DocusignView} path="/sign-document" exact />
          <PrivateRoute component={Logout} path="/logout" exact />
          {/*<PublicRoute path="/login" render={() => <Redirect to="/" />} />*/}
          <Route
            path="*"
            component={NotFound}
            render={() => <Redirect to="/notfound" />}
          />
        </Switch>
      </AnimatePresence>
    </Suspense>
  );
}
