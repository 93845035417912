import React, { lazy, Suspense, useEffect } from "react";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import PublicRoute from "./PublicRoute";
import PageLoader from "@/components/PageLoader";
import { token } from "@/auth";
import { jwtDecode } from "jwt-decode";
import history from "@/utils/history";
import { deleteCookie } from "@/auth/cookie";
import {
  ACCESS_TOKEN_NAME,
  AUTH_DATA_COOKIE,
  USER_ONBOARDING_CURRENT_STEP,
} from "@/config/serverApiConfig";

const Login = lazy(() =>
  import(/*webpackChunkName:'LoginPage'*/ "@/pages/Login")
);

const SignUp = lazy(() =>
  import(/*webpackChunkName:'SignUpPage'*/ "@/pages/Signup")
);

const NotFound = lazy(() =>
  import(/*webpackChunkName:'NotFoundPage'*/ "@/pages/NotFound")
);

export default function AuthRouter() {
  const location = useLocation();

  useEffect(() => {
    // Cheking the current token expiration and forcing a logout
    if (token.get()) {
      const decodedToken = jwtDecode(token.get());
      const today = new Date().getTime() / 1000;
      if (today > decodedToken.exp) {
        deleteCookie(ACCESS_TOKEN_NAME);
        deleteCookie(AUTH_DATA_COOKIE);
        deleteCookie(USER_ONBOARDING_CURRENT_STEP);
        history.push("/login");
      }
    }
  }, [token.get()]);

  return (
    <Suspense fallback={<PageLoader />}>
      <AnimatePresence exitBeforeEnter initial={false}>
        <Switch location={location} key={location.pathname}>
          <PublicRoute
            path="/"
            component={location.pathname === "/signup" ? SignUp : Login}
            render={() => <Redirect to="/login" />}
          />
          <PublicRoute component={Login} path="/login" exact />
          <Route
            path="/signup"
            component={SignUp}
            path="/signup"
            render={() => <Redirect to="/signup" />}
          />
          <Route
            path="*"
            component={NotFound}
            render={() => <Redirect to="/notfound" />}
          />
        </Switch>
      </AnimatePresence>
    </Suspense>
  );
}
