export const API_BASE_URL =
  process.env.NODE_ENV == "production" ||
  process.env.REACT_APP_DEV_REMOTE == "remote"
    ? "https://api-staging.nocor.com/api/"
    : "http://localhost:3001/api/";

export const ACCESS_TOKEN_NAME = "x-auth-token";
export const AUTH_DATA_COOKIE = "auth";
export const USER_ONBOARDING_CURRENT_STEP = "current-user-onboard-step";
export const DOCUSIGN_DOCUMENT_URL = "docusign-current-document-url";
